angular.module("BookingApp")

.factory "ReferralHistoryService", [

  'ApiDataService', '$q', '$window'
  (ApiDataService, $q, $window) ->

    referralHistory = undefined
    historyDeferred = $q.defer()

    getReferralLinkOrigin = ->
      if(!$window.location.origin)
        $window.location.origin = $window.location.protocol + "//" + $window.location.hostname

      $window.location.origin

    buildReferralHistory = (rawHistory) ->
      {
        referralLink: "#{getReferralLinkOrigin()}#{rawHistory.referral_path}"
        referreesCount: rawHistory.referrees_count
        referreesWithBookingCount: rawHistory.referrees_with_booking_count
        referreesWithCompletedBookingCount: rawHistory.referrees_with_completed_booking_count
        pendingPoints: rawHistory.pending_points
        claimedPoints: rawHistory.claimed_points
      }

    ->

      if referralHistory
        historyDeferred.resolve(referralHistory)
      else
        ApiDataService.call("/api/user/referrals").then (history) ->
          historyDeferred.resolve(buildReferralHistory(history))

      historyDeferred.promise
]
