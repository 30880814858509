partnerSelector = () ->
  (scope, element, attrs) ->

    scope.scroll = (e) ->
      scrollHeight = $('.selector-body').prop('scrollHeight')
      $('.selector-body').scrollTop(scrollHeight)
      e.stopPropagation()
      return

    return

angular.module('BookingApp')
  .directive 'partnerSelector', partnerSelector
