require('../services/referral-history')

angular.module('BookingApp')

.controller 'AccReferralCtrl', [

  "$scope", "ReferralPrefetch"
  ($scope, ReferralPrefetch) ->

    $scope.referralHistory = ReferralPrefetch

]
