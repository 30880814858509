require('../services/api-data.service')
require('../factories/points-partner')

angular.module("BookingApp")
.factory "KaligoMembership", [

  "ApiDataService", "AppConfig", "PointsPartner"
  (ApiDataService, AppConfig, PointsPartner) ->

    KaligoMembership = (data) ->
      self = this
      self.valid_partner = false

      if data
        angular.extend(self, data)
        if self.points_partner_id
          partnerList = AppConfig.partners.partners
          angular.forEach partnerList, (partnerData) ->
            if partnerData.id is self.points_partner_id
              self.valid_partner = true
              self.points_partner = new PointsPartner(partnerData)
      else
        self.id = 0
        self.member_first_name = ""
        self.member_last_name = ""
        self.member_no = ""
        self.points_partner_id = 0
      
      if !self.valid_partner
        self.points_partner = undefined
        self.points_partner_id = 0

      @fetchAll = () ->
        ApiDataService.get('memberships')

      @add = () ->
        ApiDataService.post('memberships', postObject())

      @update = () ->
        ApiDataService.put('memberships/' + self.id, postObject())

      @delete = () ->
        ApiDataService.delete('memberships/' + self.id)

      @validate = () ->
        ApiDataService.post('memberships/validate', postObject())
      
      @validateMemberNo = () ->
        ApiDataService.post('memberships/validate_member_no', postObject())

      postObject = () ->
        return {
          id:                   self.id
          points_partner_id:    self.points_partner_id
          member_first_name:    self.member_first_name || null
          member_last_name:     self.member_last_name || null
          member_no:            self.member_no
        }

      return self

    return (KaligoMembership)
]
