require('../services/sort.service')
require('../factories/membership')
require('../directives/partner-selector')

angular.module('BookingApp')
.controller 'AccMembershipCtrl', [

  "$scope", "$rootScope", "$timeout", "$modal", "SortService", "MembershipsPrefetch", "KaligoMembership"
  ($scope, $rootScope, $timeout, $modal, SortService, MembershipsPrefetch, KaligoMembership) ->

    $scope.memberships = []
    angular.forEach MembershipsPrefetch, (membershipRecord) ->
      $scope.memberships.push( new KaligoMembership(membershipRecord) )
    SortService.int($scope.memberships, "id", true)

    $scope.pageState = {
      isSavedSuccessfully: null
    }

    alertMessageResetAsync = () ->
      $timeout () ->
        $scope.pageState.isSavedSuccessfully = null
      , 5000

    openFormModal = (membership) ->
      modalInstance = $modal.open
        animation: true
        templateUrl: "/html/modals/account_membership_form_modal"
        controller: "MembershipFormModalInstanceCtrl"
        windowClass: "membership-form-popup"
        size: "sm"
        resolve:
          membership: () ->
            membership
          isValidating: () ->
            $rootScope.isValidating

      modalInstance.result.then (newMembership) ->
        $scope.pageState.isSavedSuccessfully = true
        alertMessageResetAsync()

        membershipInList = null
        for membership in $scope.memberships
          if membership.id == newMembership.id
            membershipInList = membership
            break

        if membershipInList
          newMembership.points_partner = $rootScope.pointsPartners.findById(newMembership.points_partner_id)
          angular.extend(membershipInList, newMembership)
        else
          $scope.memberships.push(new KaligoMembership(newMembership))

        SortService.int($scope.memberships, "id", true)
        return

      return

    $scope.addMembership = () ->
      openFormModal()

    $scope.editMembership = (membership) ->
      openFormModal(membership)
]

.controller "MembershipFormModalInstanceCtrl", [

  "$rootScope", "$scope", "$modalInstance", "KaligoMembership", "membership", "$timeout"
  ($rootScope, $scope, $modalInstance, KaligoMembership, membership, $timeout) ->

    $scope.membership = new KaligoMembership(membership)
    $scope.state = {
      form: null
    }

    dontRequireUpdate = ->
      return false if !membership
      return (
        $scope.membership.points_partner_id == membership.points_partner_id &&
        $scope.membership.member_no == membership.member_no &&
        $scope.membership.member_first_name == membership.member_first_name &&
        $scope.membership.member_last_name == membership.member_last_name
      )

    resetMemberNoValidities = ->
      $scope.state.form.member_no.$setValidity("serverValidation", true)
      $scope.state.form.member_no.$setValidity("invalidPointsPartner", true)
      $scope.state.form.member_no.$setValidity("required", true)

    $scope.validateMembershipNo = () ->
      $rootScope.isValidating = true
      $scope.membership.validateMemberNo().then(
        (membershipValidateRes) ->
          resetMemberNoValidities()
          $rootScope.isValidating = false
          return
        , (membershipValidateRes) ->
          errors = membershipValidateRes.errors
          return if !$scope.state.form
          if errors.member_no
            resetMemberNoValidities()
            errorMsg = if errors.member_no == "no member number" then "required" else "serverValidation"
            $scope.state.form.member_no.$setValidity(errorMsg, false)
          else if errors.points_partner
            resetMemberNoValidities()
            $scope.state.form.member_no.$setValidity(
              "invalidPointsPartner", false
            )
          $rootScope.isValidating = false
          return
      )

    $scope.submitMembership = () ->
      $scope.state.form.pointsPartner.$setDirty() if $scope.state.form.pointsPartner
      $scope.state.form.member_no.$setDirty() if $scope.state.form.member_no
      $scope.state.form.member_first_name.$setDirty() if $scope.state.form.member_first_name
      $scope.state.form.member_last_name.$setDirty() if $scope.state.form.member_last_name
      $scope.state.form.pointsPartner.$setValidity("required", false) if !$scope.membership.points_partner_id
      return if $scope.state.form.$invalid || !$scope.membership.points_partner_id
      return $modalInstance.dismiss("no change") if dontRequireUpdate()

      if membership && $scope.membership.member_no == membership.member_no
        updateMembership($scope.membership)
        return

      $scope.membership.validate().then(
        (membershipValidateRes) ->
          updateMembership($scope.membership)
          return
        , (membershipValidateRes) ->
          errors = membershipValidateRes.errors
          return if !$scope.state.form
          if errors.member_no
            $scope.state.form.member_no.$setValidity("serverValidation", false)
          if errors.member_first_name
            $scope.state.form.member_first_name.$setValidity("required", false)
          if errors.member_last_name
            $scope.state.form.member_last_name.$setValidity("required", false)
          return
      )

    updateMembership = (membership) ->
      if membership.id
        membership.update().then (membershipRes) ->
          $modalInstance.close(membershipRes)
        , () ->
          $scope.pageState.isSavedSuccessfully = false
          $modalInstance.dismiss("errors")
      else
        membership.add().then (membershipRes) ->
          $modalInstance.close(membershipRes)
        , () ->
          $scope.pageState.isSavedSuccessfully = false
          $modalInstance.dismiss("errors")
      return

    $scope.cancel = () ->
      $modalInstance.dismiss('cancel')

    $scope.changePartner = () ->
      $scope.membership.points_partner = $rootScope.pointsPartners.findById($scope.membership.points_partner_id)
      if $scope.membership.points_partner.requireMemberNoOnly
        $scope.membership.member_first_name = ""
        $scope.membership.member_last_name = ""
      return

    $scope.startSelectPartner = () ->
      $timeout( ->
        $scope.membership.isSelectingPartner = true
        $scope.membership.showPartnerSelector = true
        $rootScope.lockBody = "lock-body"
      )
      return

    $scope.membership.finishSelectPartner = (partnerId) ->
      if partnerId
        $scope.membership.points_partner_id = partnerId
        $scope.changePartner()
      $scope.membership.isSelectingPartner = false
      $scope.membership.showPartnerSelector = false
      $rootScope.lockBody = ""
      $scope.state.form.pointsPartner.$setValidity("required", true)
      return
]
