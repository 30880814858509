declare var angular: any;

import { AppSettings } from 'booking_app/values/app-settings';

angular.module("BookingApp").config([
  "$routeProvider", "$injector",
  ($routeProvider, $injector) => { },
]);

class KaligoCtrl {

  static $inject = ["AppSettings"];

  constructor(
    private appSettings: AppSettings,
  ) {
    this.appSettings.enableChildrenSearch = true;
    this.appSettings.displayPurchaseAccessAndCancelText = true;
    this.appSettings.hotelDetailsTemplateConfig.showBgPageColor = true;
    this.appSettings.settingBasedColor = true;
    this.appSettings.hasCouponCode = false;
    this.appSettings.newLandingPageTiles = false;
    this.appSettings.enableUserActionTracking = true;
    this.appSettings.stripePaymentIntentsEnabled = true;
    this.appSettings.showLoyaltyProgramTnC = true;
    this.appSettings.alwaysShowCancellationFee = true;
  }

}

angular.module("BookingApp").controller("KaligoCtrl", KaligoCtrl);
