global.loadWlDeps = function() {
  require('angular-counter/js/angular-counter');
}

global.wlAngularDeps = [
  'counter',
  'ngAnimate',
]

global.loadAppDeps = function() {
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/kaligo/locales');
  require('../../assets/javascripts/booking_app/controllers/acc-referral-controller');
  require('../../assets/javascripts/booking_app/controllers/acc-membership-controller');
  require('../../assets/javascripts/whitelabel/kaligo/controllers/kaligo-controller');
}

require('../application');
